import {
  Button,
  Col,
  Form,
  FormInstance,
  message,
  Modal,
  Popover,
  Upload,
} from "antd";
import { RcFile, UploadFile } from "antd/lib/upload";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import useStore from "../../../../store";
import {
  createUrl,
  planMessage,
  uploadedFileOnPreview,
} from "../../../../config/Global";
import { CONSTANT } from "../../../../config/Constant";
import GenerateAIImage from "../components/GenerateAIImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import CropImageUrlElement from "../components/CropImageUrlElement";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  layoutIdFlag?: boolean;
}

const BackgroundImageElement: React.FC<formInterface> = observer((props) => {
  const { form, onChange, name, layout_id, layoutIdFlag } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [applyGenerateAIImageModal, setApplyGenerateAIImageModal] =
    useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [applyImageUrlModal, setApplyImageUrlModal] = useState<boolean>(false);

  const { LAYOUT, AUTH } = useStore();

  useEffect(() => {
    if (LAYOUT.editValues && form.getFieldValue(name)) {
      setFileList([
        {
          uid: "-1",
          name: form.getFieldValue(name),
          status: "done",
          url: createUrl("/storage/media/file/" + form.getFieldValue(name)),
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (LAYOUT.listAiImages && LAYOUT.listAiImages?.field_name === name) {
      const aiImages1 = LAYOUT?.listAiImages?.images?.map((value: number) => {
        const list = LAYOUT?.aiImage?.find((data: number, index: number) => {
          return index === value;
        });
        return list;
      });

      const fileList = aiImages1?.map((data: any) => ({
        uid: "-1",
        name: `${data?.name}`,
        status: "done",
        url: data?.image_resource_url,
      }));

      setFileList(fileList);
      form.setFieldsValue({
        [name]: {
          aiGenerated: {
            type: "aiGenerated",
            name: fileList[0]?.name,
            url: fileList[0]?.url,
          },
        },
      });
      onChange();
    }
  }, [LAYOUT.listAiImages]);

  useEffect(() => {
    if (layout_id && layoutIdFlag) {
      setFileList([]);
      form.setFieldsValue({ [name]: null });
    }
  }, [layout_id]);

  //For showing modal on file removal :Start
  const toggle = () => {
    setVisible((prev) => !prev);
    return false;
  };

  const deleteItem = () => {
    if (typeof form.getFieldValue(name) !== "object") {
      const filePath = "/storage/media/file/" + form.getFieldValue(name);
      LAYOUT.RemoveFileApi(LAYOUT.editValues.id, filePath);
      toggle();
    }
    setFileList([]);
    form.resetFields([name]);
    onChange();
    return true;
  };
  //For showing modal on file removal :End

  // For upload image through web url
  const handleUpload = async (
    croppedImage: string,
    originalFileName: string
  ) => {
    const response = await fetch(croppedImage);
    const blob = await response.blob();
    let fileExtension;

    switch (blob.type) {
      case "image/jpeg":
        fileExtension = "jpeg";
        break;
      case "image/jpg":
        fileExtension = "jpg";
        break;
      case "image/png":
        fileExtension = "png";
        break;
      case "image/gif":
        fileExtension = "gif";
        break;
      case "image/webp":
        fileExtension = "webp";
        break;
      default:
        fileExtension = "jpeg";
    }
    const newFile: any = [
      {
        uid: "-1",
        name: `${originalFileName}.${fileExtension}`,
        status: "done",
        url: croppedImage,
      },
    ];
    setFileList(newFile);

    const fileWithOriginalName = new File(
      [blob],
      `${originalFileName}.${fileExtension}`,
      { type: blob.type }
    );
    form.setFieldsValue({
      [name]: fileWithOriginalName,
    });

    onChange();
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <Col>
        <Form.Item
          name={name}
          className="full-width-dragger"
          label={
            <div>
              Background Image
              {AUTH.checkPlan(CONSTANT.INTERACTIVE_CONTENT_SUPPORT) ? (
                <>
                  {/* <Button
                    type="primary"
                    title="Generate AI Image"
                    onClick={() => setApplyGenerateAIImageModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    Generate AI Image
                  </Button> */}
                  <Button
                    type="primary"
                    title="Image Url"
                    onClick={() => setApplyImageUrlModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </Button>
                </>
              ) : (
                <Popover title={() => planMessage()}>
                  {/* <Button
                    type="primary"
                    title="Generate AI Image"
                    disabled={true}
                    onClick={() => setApplyGenerateAIImageModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    Generate AI Image
                  </Button> */}
                  <Button
                    type="primary"
                    title="Image Url"
                    disabled={true}
                    onClick={() => setApplyImageUrlModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </Button>
                </Popover>
              )}
            </div>
          }
          extra={"This widget will display Background image of layout"}
        >
          <Upload
            customRequest={dummyRequest}
            accept=".png, .jpg, .jpeg, .gif"
            listType="picture"
            multiple={false}
            fileList={fileList}
            onRemove={
              typeof form.getFieldValue(name) !== "object" ? toggle : deleteItem
            }
            onPreview={uploadedFileOnPreview}
            showUploadList={{ showPreviewIcon: false }}
            onChange={({ fileList }: any) => {
              setFileList(fileList);
              form.setFieldsValue({
                [name]: fileList[0]?.originFileObj,
              });
              onChange();
            }}
            beforeUpload={(file: RcFile) => {
              const isJpgOrPng =
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png" ||
                file.type === "image/gif";
              const isLt1M = file.size / 1024 / 1024 < 9;

              if (!isJpgOrPng) {
                message.error(
                  `Please upload file should be png, jpg , jpeg or gif`
                );
                return Upload.LIST_IGNORE;
              } else if (!isLt1M) {
                message.error("File size should not be more than 9 MB");
                return Upload.LIST_IGNORE;
              } else {
                setFileList([file]);
                return false;
              }
            }}
          >
            {fileList.length < 1 && (
              <Button icon={<UploadOutlined />}>Upload</Button>
            )}
          </Upload>
        </Form.Item>
        {applyGenerateAIImageModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <GenerateAIImage
              type={"single"}
              name={name}
              visible={applyGenerateAIImageModal}
              close={() => {
                setApplyGenerateAIImageModal(false);
              }}
            />
          </Form.Item>
        )}
        {applyImageUrlModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <CropImageUrlElement
              visible={applyImageUrlModal}
              onUpload={handleUpload}
              cancel={() => {
                setApplyImageUrlModal(false);
              }}
              flagBackgroundImage={true} //flag to bypass the cropping

            />
          </Form.Item>
        )}
      </Col>
      <Modal
        okText="Yes"
        cancelText="No"
        open={visible}
        onOk={deleteItem}
        onCancel={toggle}
        className="removeUploadModal commanModal"
      >
        <h4>Are you sure you want to permanently remove this Item ?</h4>
      </Modal>
    </>
  );
});

export default BackgroundImageElement;
