import React from "react";
import { notification } from "antd";
import { StyleProps } from "../store/LayoutStore/LayoutInterface";
import { RcFile } from "antd/lib/upload";
import CryptoJS from "crypto-js";
import { CONSTANT } from "./Constant";
import moment from "moment";
import { Link } from "react-router-dom";
import { ReactComponent as UpgradeIcon } from "../assets/images/upgrade_plan.svg";
import { debounce } from "lodash";

export const displayFormats = {
  DATE_FORMAT: "DD-MM-YYYY HH:mm:ss",
  TEMP_DATE_FORMAT: "YYYY-MM-DD",
  TIME_FORMAT: "HH:mm",
  TIME_FORMAT_Y: "HH:mm:ss",
  DATE_TIME_FORMAT: "DD-MM-YYYY HH:mm",
  DATE_MONTH_YEAR: "DD-MM-YYYY",
  DATE_TIME_FORMAT_Y: "YYYY-MM-DD HH:mm",
  DATE_FORMAT_Y: "YYYY-MM-DD HH:mm:ss"
};

export const passwordTooltip = (
  <div>
    <div>at least 1 numeric character</div>
    <div>at least 1 special character</div>
    <div>at least 1 uppercase letter</div>
    <div>at least 6 character</div>
  </div>
);

export const isActiveStatus = {
  1: "Activated",
  0: "Deactivated",
};

export const isChecked = [
  { id: 1, value: "Yes" },
  { id: 0, value: "No" },
];

export const statusFilter = [
  { id: 1, value: "Activated" },
  { id: 0, value: "Deactivated" },
];

export const statusFilterSecond = [
  { id: 1, value: "Active" },
  { id: 0, value: "Inactive" },
];

export const mobileValidation = (mobile: any) => {
  if (mobile && typeof mobile === "string") {
    const wrongNumberArr = [
      "6666666666",
      "7777777777",
      "8888888888",
      "9999999999",
      "0000000000",
    ];
    const staringStr = ["0", "1", "2", "3", "4", "5"];
    const stringWith = mobile.substring(0, 1);
    if (
      mobile.length === 12 &&
      !wrongNumberArr.includes(mobile) &&
      !staringStr.includes(stringWith)
    ) {
      return true;
    }
  }
  return false;
};

export const uploadedFileOnPreview = async (file: any) => {
  let src = file.url as string;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as RcFile);
      reader.onload = () => resolve(reader.result as string);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow: any = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

export const widgetStyles = {
  ImageElement: (): StyleProps["styles"] => [
    "bgColor",
    "textColor",
    "fontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  popup: (): StyleProps["styles"] => [
    "bgColor",
    "textColor",
    "fontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  logo: (): StyleProps["styles"] => [
    "bgColor",
    "textColor",
    "fontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  Title: (): StyleProps["styles"] => [
    "textAlign",
    "bgColor",
    "textColor",
    "fontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  Video: (): StyleProps["styles"] => [
    "bgColor",
    "textColor",
    "fontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
    "objectFit"
  ],
  priceTitle: (): StyleProps["styles"] => [
    "colorStyle",
    "applyFormat",
    "bgColor",
    "titleBgColor",
    "titleFontColor",
    "oddRowBgColor",
    "oddRowFontColor",
    "evenRowBgColor",
    "evenRowFontColor",
    "textFontWeight",
    "rateFontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  Ticker: (): StyleProps["styles"] => [
    "bgColor",
    "textColor",
    "fontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  Html: (): StyleProps["styles"] => [
    "applyScroll",
    "bgColor",
    "textColor",
    "fontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  slider: (): StyleProps["styles"] => [
    "sliderTimeOut",
    "bgColor",
    "textColor",
    "fontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  donorWall: (): StyleProps["styles"] => [
    "orderBy",
    "orderDirectory",
    "colorStyle",
    "bgColor",
    "titleBgColor",
    "titleFontColor",
    "oddRowBgColor",
    "oddRowFontColor",
    "evenRowBgColor",
    "evenRowFontColor",
    "textFontWeight",
    "rateFontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  event: (): StyleProps["styles"] => [
    "fontFamily",
    "fontWeight",
    "fontSize",
    "iconColor",
    "iconFontSize",
    "first_event_color",
    "second_event_color",
    "third_event_color",
    "four_event_color",
  ],
  twittPost: (): StyleProps["styles"] => ["twittPost"],
  twittImage: (): StyleProps["styles"] => ["twittPost", "sliderTimeOut"],
  twittTicker: (): StyleProps["styles"] => ["twittPost"],
  twittEmbeded: (): StyleProps["styles"] => [
    "bgColor",
    "textColor",
    "fontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  twittVideo: (): StyleProps["styles"] => [
    "bgColor",
    "textColor",
    "fontWeight",
    "margin",
    "padding",
    "fontFamily",
    "fontSize",
    "applyBorder",
    "borderColor",
    "borderStyle",
    "borderWidth",
  ],
  PdfElement: (): StyleProps["styles"] => [
    "ScollingSpeed",
    "ScollingDelay",
    "ScollingInterval",
  ],
};

export const Notification = {
  success: (data: any) => {
    notification.success({
      placement: data.placement ? data.placement : "bottomRight",
      duration: 3,
      ...data,
    });
  },
  error: (data: any) => {
    notification.error({
      placement: data.placement ? data.placement : "bottomRight",
      duration: 3,
      ...data,
    });
  },
};

export const snake_case_string = (str: any) => {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((s: any) => s.toLowerCase())
      .join("_")
  );
};

export const camel_case_string = (str: any) => {
  str = str
    .replace(/[-_]+/g, " ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: any, index: any) {
      return index !== 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/ (.)/g, function ($1: any) {
      return $1.toUpperCase();
    });
  return str;
};

export const convertTextToID = (
  text_array: any,
  main_array: any,
  text_key: any,
  id_key: any
) => {
  const new_array: any = [];
  if (text_array && text_array.values && text_array.values.length > 0) {
    text_array.values.forEach((x: any) => {
      var temp = main_array.find((y: any) => y[text_key] === x);
      if (x && temp) {
        new_array.push(temp[id_key]);
      } else {
        insertAt(new_array, 0, x);
      }
    });
  }
  return new_array;
};

function insertAt(array: any, index: any, ...elementsArray: any) {
  array.splice(index, 0, ...elementsArray);
}

export const dataToFormDataConverter = (data: any) => {
  const formData = new FormData();
  for (let name in data) {
    const value = data[name];

    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        name = `${name}[]`;
        value.map((item) => {
          formData.append(name, item);
        });
      } else {
        formData.append(name, value);
      }
    }
  }
  return formData;
};

export const agDateColumnFilter = {
  comparator: (filterDate: any, cellValue: any) => {
    const dateAsstring = cellValue;
    if (dateAsstring === null) {
      return 0;
    }
    if (dateAsstring === "N/A") {
      return -1;
    }
    const dateParts = dateAsstring.split("-");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (cellDate < filterDate) {
      return -1;
    } else if (cellDate > filterDate) {
      return 1;
    }
    return 0;
  },
  browserDatePicker: true,
};

export const agDateTimeColumnFilter = {
  comparator: (filterDate: any, cellValue: any) => {
    // Slice the Z from the end of the timestamp string:
    filterDate = new Date(filterDate);
    cellValue = new Date(cellValue);
    const filterBy = filterDate.getTime();
    const filterMe = cellValue.getTime();
    
    if (filterBy === filterMe) {
      return 0;
    }

    if (filterMe < filterBy) {
      return -1;
    }

    if (filterMe > filterBy) {
      return 1;
    }
  },
  defaultOption: "inRange",
};

// export const dateComparator = {
// 	comparator: (
// 	filterLocalDateAtMidnight: Date,
// 	cellValue: string
//   ): number => {
// 	const dateAsString = moment(cellValue).format("DD-MM-YYYY");
// 	const dateParts = dateAsString.split("-");
// 	const cellDate = new Date(
// 	  Number(dateParts[2]),
// 	  Number(dateParts[1]) - 1,
// 	  Number(dateParts[0])
// 	);

// 	if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
// 	  return 0;
// 	}
// 	if (cellDate < filterLocalDateAtMidnight) {
// 	  return -1;
// 	}
// 	if (cellDate > filterLocalDateAtMidnight) {
// 	  return 1;
// 	}
// 	return 1;},
// 	defaultOption: "inRange",
//   };

export const dateComparator = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
    const dateAsString = moment(cellValue, "DD-MM-YYYY HH:mm").format(
      "DD-MM-YYYY HH:mm"
    );
    const dateParts = dateAsString.split(/ |:|-/);
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
      Number(dateParts[3]),
      Number(dateParts[4])
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 1;
  },
  defaultOption: "inRange",
};

export const objectToRGB = (rgbObj: any) => {
  return `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, ${rgbObj.a})`;
};

export const Constant = {
  API_KEY: "secretapikey",
};

export const stringEncryption = (string: string) => {
  return CryptoJS.AES.encrypt(string, Constant.API_KEY)
    .toString()
    .replace(/\+/g, "xMl3Jk")
    .replace(/\//g, "Por21Ld")
    .replace(/=/g, "Ml32");
};

export const stringDecryption = (string: string) => {
  string = string
    .replace(/xMl3Jk/g, "+")
    .replace(/Por21Ld/g, "/")
    .replace(/Ml32/g, "=");
  return CryptoJS.AES.decrypt(string, Constant.API_KEY).toString(
    CryptoJS.enc.Utf8
  );
};

export const createUrl = (path: string, baseUrl = CONSTANT.BASE_URL) => {
  baseUrl = baseUrl?.endsWith("/") ? baseUrl : baseUrl + "/";
  path = path.startsWith("/") ? path.substring(1) : path;
  return baseUrl + path;
};

export const planMessage = (name?: string) => {
  return (
    <div>
      <UpgradeIcon />
      <Link to={"/plan"}>
        {name && <h3 style={{ margin: 0, padding: 0 }}>{name}</h3>} Please
        Upgrade Your Plan
      </Link>
    </div>
  );
};

// For validate the form fields
export const validateFields = debounce((form, setDisabled) => {
  form
    .validateFields()
    .then(() => {
      setDisabled(false);
    })
    .catch(() => {
      setDisabled(true);
    });
}, 500);

export const numericField = (e?: any) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
