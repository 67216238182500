import React, { useEffect } from 'react'
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../../store";
import Button from "antd/es/button";
import { ReactComponent as EditIcon } from '../../../../assets/images/icon/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icon/delete.svg';
import { observer } from 'mobx-react';
import { setCustomColumnFilter } from '../../../../config/Config';
import { CONSTANT } from '../../../../config/Constant';
import { ScheduleListProps } from '../../../../store/ScheduleStore/ScheduleInterface';

const ListComponent: React.FC<ScheduleListProps> = observer((props: ScheduleListProps) => {
	const { openEditModal, openDeleteModal } = props
	const { SCHEDULE, AUTH } = useStore()
	const cellStyle = {
		textAlign: "center"
	}
	const cellRightStyle = {
		textAlign: "right",
		paddingRight: "30px"
	}
	const type = [
		{ id: 1, value: "Once" },
		{ id: 2, value: "Repeat" },
		// { id: 3, value: "Override"}
	];

	useEffect(() => {
		SCHEDULE.fetchList()
	}, [SCHEDULE])

	const ActionRenderer: React.FC = (props: any) => {
		return (
			<div className="action-column">
				{AUTH.checkPermission(11, 'edit') && (props?.data?.type != CONSTANT.SCHEDULE.TYPE.OVERRIDE) && <Button type="text" title={"Edit"} className="editIcon" onClick={() => openEditModal(props.data.schedule_id)}>
					<EditIcon />
				</Button>}
				{AUTH.checkPermission(11, 'delete') &&
					<Button type="text" title={"Delete"} className="deleteIcon" onClick={() => openDeleteModal(props.data.schedule_id)}>
						<DeleteIcon />
					</Button>}
			</div>
		);
	};

	const columnSetup = () => {
		const columns: any[] = [
			<AgGridColumn
				key='id'
				field="schedule_id"
				headerName="# ID"
				filter={"agNumberColumnFilter"}
				maxWidth={80}
				cellStyle={cellStyle}
			/>,
			<AgGridColumn
				key='device_name'
				field="name"
				headerName="Device Name"
				minWidth={200}
				maxWidth={250}
			/>,
			<AgGridColumn
				key='mode_name'
				field="mode_name"
				cellStyle={cellStyle}
				filter={'agDateColumnFilter'}
				filterParams={setCustomColumnFilter(CONSTANT.ORIANTATION_VALUE)}
				headerName="Orientation"
				maxWidth={150}
			/>,
			<AgGridColumn
				key='layout_name'
				field="l_nm"
				headerName="Layout Name"
				minWidth={200}
				maxWidth={250}
			/>,
			<AgGridColumn
				key='type_name'
				field="type_name"
				headerName="Type"
				filter={"agTextColumnFilter"}
				filterParams={setCustomColumnFilter(type)}
				cellStyle={cellStyle}
				maxWidth={100}
			/>,
			<AgGridColumn
				key='start_date_time'
				field="start_date_time"
				cellStyle={cellRightStyle}
				filter={false}
				// filterParams={agDateColumnFilter}
				headerName="Start Time"
				maxWidth={250}
			/>,
			<AgGridColumn
				key='end_date_time'
				field="end_date_time"
				cellStyle={cellRightStyle}
				filter={false}
				// filterParams={agDateColumnFilter}
				headerName="End Time"
				maxWidth={250}
			/>,
			<AgGridColumn
				colId='action'
				key='action'
				headerName="Actions"
				type="actionColumn"
				sortable={false}
				filter={false}
				maxWidth={150}
				minWidth={150}
				pinned={'right'}
			/>
		];
		return columns;
	}

	return (
		<AgGridWrapper onGridReady={SCHEDULE.setupGrid} components={{ ActionRenderer }} rowData={SCHEDULE?.list_all_schedule_data} onColumnResized={SCHEDULE.onGridChanged} onColumnMoved={SCHEDULE.onGridChanged}>
			{columnSetup()}
		</AgGridWrapper>
	)
})

export default ListComponent;