import React, { useEffect, useState } from "react";
import { Col, Form, FormInstance, Image, Row, Spin } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { LayoutRequest } from "../../../../requests/PageRequest";
import useStore from "../../../../store";
import Config from "../../../../config/Config";
import { LayoutListDP } from "../../../../store/LayoutStore/LayoutInterface";
import ImageElement from "../elements/ImageElement";
import ImageSliderElement from "../elements/SliderImageElement";
import VideoElement from "../elements/VideoElement";
import LogoElement from "../elements/LogoElement";
import BackgroundImageElement from "../elements/BackgroundImageElement";
import PriceElement from "../elements/PriceElement";
import TitleElement from "../elements/TitleElement";
import TickerElement from "../elements/TickerElement";
import DonorwallElement from "../elements/DonorwallElement";
import EventElement from "../elements/EventElement";
import TweetElement from "../elements/TwittElement";
import TweetPostElement from "../elements/TweetPostElement";
import TweetImageElement from "../elements/TweetImageElement";
import TweetTickerElement from "../elements/TweetTickerElement";
import TweetEmbeddedElement from "../elements/TweetEmbeddedElement";
import TweetVideoElement from "../elements/TweetVideoElement";
import no_image from "../../../../assets/images/no_image.jpg";
// import PopupElement from "../elements/PopupElement";
import TextElement from "../elements/TextElement";
import YoutubeElement from "../elements/YoutubeElement";
import PdfElement from "../elements/PdfElement";
import { displayFormats, objectToRGB } from "../../../../config/Global";
import XYValElement from "../elements/XYValElement";
import TypeElement from "../elements/TypeElement";
import HtmlLayoutElement from "../elements/HtmlLayoutElement";
import TextFieldElement from "../elements/TextFieldElement";
import ScheduleElement from "../elements/ScheduleElement";
import moment from "moment";
import TextAreaElement from "../elements/TextAreaElement";
import { CONSTANT } from "../../../../config/Constant";
import CheckBoxElememt from "../elements/CheckBoxElememt";
import MixLayout from "../elements/MixLayout";
import BackgroundMusicElement from "../elements/BackgroundMusicElement";

interface formInterface {
  id: string;
  form: FormInstance;
  handleSubmit: (data: any) => void;
  onChange: () => void;
  type: string;
  setShow?: () => void;
}
const FormComponent: React.FC<any> = observer((props: formInterface) => {
  const { LAYOUT, AUTH } = useStore();
  const [img, setImg] = useState("");
  const { form, handleSubmit, onChange, id, setShow } = props;
  const [layoutData, setLayoutData] = useState<boolean>(true);
  const [layoutFilterData, setLayoutFilterData] = useState<any>(null);
  const [layoutIdFlag, setLayoutIdFlag] = useState<boolean>(false);
   
  useEffect(() => {
    if (LAYOUT.editValues && props.type) {
      const copyStyleProps: any = {};
      const extraFields: any = {};
      
      LAYOUT.editValues.layout_field.forEach((_x: any) => {
        const localName = _x["shortcode"].includes("_url")
          ? _x["shortcode"].split("_url")[0].split("-")
          : _x["shortcode"].split("-");
        const shortCode = _x["shortcode"].split("_url")[0];
        let jsonValue = _x.json_value
          ? _x.json_value.replaceAll("\\/", "/")
          : null;

        if (_x["shortcode"].includes("_url")) {
          jsonValue = jsonValue.includes(",")
            ? jsonValue.split(",")
            : jsonValue;
        }

        if (localName[0] === "slider") {
          if (!Array.isArray(jsonValue)) {
            jsonValue = [jsonValue];
          }

          if (localName.length === 2) {
            if (!extraFields[shortCode]) {
              extraFields[shortCode] = jsonValue;
            } else {
              extraFields[shortCode].push(jsonValue);
            }
          } else {
            if (localName[0] === "slider") {
              if (!extraFields[shortCode]) {
                extraFields[shortCode] = jsonValue;
              } else {
                extraFields[shortCode].push(jsonValue);
              }
            } else {
              extraFields[shortCode] = jsonValue;
            }
          }
        } else {
          extraFields[shortCode] = jsonValue;
        }
        if (_x["layout_field_attr"].length) {
          copyStyleProps[shortCode] = _x["layout_field_attr"];
        }
        if (_x["shortcode"] === "professor_schedule") {
          /* extraFields[_x["shortcode"]] */
          var professor_schedule = JSON.parse(jsonValue);
          Object.entries(professor_schedule.schedule).forEach(
            ([key, value]: any) => {
              if (value) {
                value?.forEach((val: any, index: number) => {
                  professor_schedule.schedule[key][index].from_time = moment(
                    val.from_time,
                    displayFormats.TIME_FORMAT
                  );
                  professor_schedule.schedule[key][index].to_time = moment(
                    val.to_time,
                    displayFormats.TIME_FORMAT
                  );
                });
              }
            }
          );

          professor_schedule.holiday?.forEach((val: any, index: number) => {
            professor_schedule.holiday[index].from_date = moment(val.from_date);
            professor_schedule.holiday[index].to_date = moment(val.to_date);
          });

          extraFields[_x["shortcode"]] = professor_schedule;

          if (_x["shortcode"] === "slider_random") {
            extraFields[_x["shortcode"]] =
              JSON.parse(jsonValue) == "true" ? true : false;
          }
        }
        if (_x["shortcode"] === "layout_name") {
          extraFields[shortCode] = 
              JSON.parse(jsonValue)
        }
      });
      form.setFieldsValue({
        oriantation: LAYOUT.editValues.oriantation.toString(),
        name: LAYOUT.editValues.name,
        global_layout_id: LAYOUT.editValues.global_layout_id,
        description: LAYOUT.editValues.description,
        ...extraFields,
      });

      setImg(LAYOUT.editValues.global_layout_id);

      LAYOUT.styleProps = copyStyleProps;
    }
  }, [LAYOUT.editValues]);

  useEffect(() => {
    LAYOUT.getLayout(AUTH?.user?.id).then(() => setLayoutData(false));
    onChange();
  }, []);

  useEffect(() => {
    const result = LAYOUT.layoutList?.data?.map((item2: any) => {

      switch (item2.name) {
        case "Build Your Own Layout":
          return (
              AUTH.user?.company_id
              ? AUTH.checkPlan(CONSTANT.BUILD_YOUR_OWN_LAYOUT)
              : true
          )
            ? { ...item2, disable: false }
            : { ...item2, disable: true };

        case "Youtube Video ":
          return (
            AUTH.user?.company_id
              ? AUTH.checkPlan(CONSTANT.LIVE_BROADCAST)
              : true
          )
            ? { ...item2, disable: false }
            : { ...item2, disable: true };
    
        case "Facebook Feeds":
        case "Instagram Photo/Insta Live":
        case "Twitter Bjp Demo":
        case "Twitter Bjp Demo 1":
        case "Twitter New":
        case "Twitter Post":
        case "Twitter Post Demo":
          return (
            AUTH.user?.company_id
              ? AUTH.checkPlan(CONSTANT.INT_WITH_SOCIAL_MEDIA)
              : true
          )
            ? { ...item2, disable: false }
            : { ...item2, disable: true };
        default:
          return { ...item2, disable: false };
      }
    });
    setLayoutFilterData(result);
  }, [LAYOUT.layoutList]);

  return (
    <FormBox
      id={id}
      form={form}
      onFinish={handleSubmit}
      onValuesChange={onChange}
      initialValues={LAYOUT.initialValues}
    >
      <Row gutter={Config.gutter}>
        <Col span={6}>
          <InputBox.Radio
            type="button"
            name="oriantation"
            label="Layout mode"
            onChange={() => {
              onChange();
              form.resetFields(["global_layout_id"]);
              setLayoutIdFlag(true);
              setImg(form.getFieldValue(["global_layout_id"]));
            }}
            options={{
              list: [
                { id: "0", name: "Landscape" },
                { id: "1", name: "Portrait" },
              ],
            }}
          />
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.global_layout_id !== curValues.global_layout_id
            }
          >
            {() => {
              if (layoutFilterData && layoutFilterData?.length) {
                const selectedLayout = layoutFilterData.find(
                  (_x: any) => _x.id === form.getFieldValue("global_layout_id")
                );
                return (
                  <Image
                    src={
                      selectedLayout &&
                      selectedLayout.image &&
                      img !== undefined
                        ? require("../../../../assets/images/layouts_default_image/" +
                            selectedLayout.image)
                        : no_image
                    }
                  />
                );
              }
            }}
          </Form.Item>
        </Col>
        <Col span={18}>
          <Row gutter={Config.gutter}>
            <Col span={12}>
              <InputBox.Text
                name="name"
                label="Layout Name"
                placeholder="Layout Name"
                rules={LayoutRequest.layout_name}
                onChange={onChange}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.oriantation !== curValues.oriantation
                }
              >
                {() => {
                  return (
                    <InputBox.Select
                      name="global_layout_id"
                      className="selectDown"
                      label="Select Layout"
                      placeholder="Select Layout"
                      notFoundContent={
                        layoutData ? <Spin /> : "No content found"
                      }
                      rules={LayoutRequest.global_layout_id}
                      onChange={() => {
                        onChange();
                        setLayoutIdFlag(true);
                        setImg(form.getFieldValue(["global_layout_id"]));
                        form.resetFields(["background-color","audio_status","bg_music"]);
                      }}
                      showSearch
                      filterOption={(input, option: any) => {
                        const isOptionDisabled = option?.disabled;
                        let optionText: string;
                        isOptionDisabled
                          ? (optionText = (
                              option?.children?.props?.children || ""
                            ).toString())
                          : (optionText = (option?.children || "").toString());

                        return optionText
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      options={{
                        list: layoutFilterData,
                        rejectedValues: layoutFilterData
                          ? form.getFieldValue("oriantation") === "0"
                            ? layoutFilterData
                                .filter((_x: any) => _x.oriantation !== 0)
                                .map((item: any) => item.id)
                            : layoutFilterData
                                .filter((_x: any) => _x.oriantation !== 1)
                                .map((item: any) => item.id)
                          : null,
                        requiredValues: [],
                        disabled_keys:
                          layoutFilterData &&
                          layoutFilterData
                            .filter((item: any) => item.disable === true)
                            .map((item: any) => item.id),
                      }}
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={12}>
              <InputBox.TextArea
                name="description"
                label="Description"
                placeholder="Description"
                rules={LayoutRequest.description}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.global_layout_id !== curValues.global_layout_id
                }
              >
                {() => {
                  return (
                    form &&
                    form.getFieldValue("global_layout_id") && (
                      <Col span={24}>
                        <InputBox.ColorPicker
                          label={"Background Color"}
                          name={`background-color`}
                          onChange={(color: any) => {
                            setTimeout(() => {
                              form.setFieldValue(
                                "background-color",
                                objectToRGB(color)
                              );
                            }, 0);
                            onChange();
                          }}
                          placeholder="Change Background color"
                        />
                      </Col>
                    )
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.global_layout_id !== curValues.global_layout_id
          }
        >
          {() => {
            return (
              form &&
              ![79, 80].includes(form.getFieldValue("global_layout_id")) && //Remove BackGround music for mix layouts
              form.getFieldValue("global_layout_id") && (
                <BackgroundMusicElement 
                  global_layout_id={img}
                  onChange={onChange}
                  form={form}
                  name={"bg_music"}
                  layoutIdFlag = {layoutIdFlag}
                />
              )
            );
          }}
      </Form.Item>  
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.global_layout_id !== curValues.global_layout_id ||
          prevValues.oriantation !== curValues.oriantation ||
          prevValues["type-1"] !== curValues["type-1"] ||
          prevValues["type-2"] !== curValues["type-2"]
        }
      >
        {() => {
          if (layoutFilterData && form.getFieldValue("global_layout_id")) {
            const getWidget = layoutFilterData.filter(
              (_x: LayoutListDP) =>
                _x.id === form.getFieldValue("global_layout_id")
            )[0]?.used_widgets;

            if (getWidget) {
              return (
                <Row gutter={Config.gutter} className="layoutOptions">
                  {getWidget.split(",").map((_x: string, index: number) => {
                    if (
                      ["image", "video", "youtube"].includes(_x.split("-")[0])
                    ) {
                      const typeVal = form.getFieldValue(
                        "type-" + _x.split("-")[1]
                      );
                      if (
                        typeVal === "1" &&
                        "image-" + _x.split("-")[1] !== _x
                      ) {
                        return;
                      }
                      if (
                        typeVal === "2" &&
                        "video-" + _x.split("-")[1] !== _x
                      ) {
                        return;
                      }
                      if (
                        typeVal === "3" &&
                        "youtube-" + _x.split("-")[1] !== _x
                      ) {
                        return;
                      }
                    }
                    if (_x.split("-")[0] === "image") {
                      return (
                        <Col span={12} key={index}>
                          <ImageElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x.toLowerCase()}
                            label={_x}
                            images={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    // if (
                    //   _x.split("-")[0] === "popup" &&
                    //   ![CONSTANT.SuperAdmin].includes(AUTH.user.users_roles_id)
                    // ) {
                    //   if (
                    //     ![
                    //       CONSTANT.SuperAdmin,
                    //       CONSTANT.SubAdmin,
                    //       CONSTANT.Partner,
                    //     ].includes(AUTH.user.users_roles_id)
                    //       ? AUTH.checkPlan(CONSTANT.GLOBAL_ANNOUNCEMENTS)
                    //       : true
                    //   ) {
                    //     return (
                    //       <Col span={24} key={index}>
                    //         <PopupElement
                    //           setShow={setShow}
                    //           onChange={onChange}
                    //           form={form}
                    //           name={_x}
                    //           label={_x}
                    //           layout_id={img}
                    //           layoutIdFlag = {layoutIdFlag}
                    //             disable={false}
                    //         />
                    //       </Col>
                    //     );
                    //   } else {
                    //     return (
                    //       <Col span={24} key={index}>
                    //         <Tooltip title="Please Upgrade Your Plan">
                    //           <PopupElement
                    //             setShow={setShow}
                    //             onChange={onChange}
                    //             form={form}
                    //             name={_x}
                    //             label={_x}
                    //             layout_id={img}
                    //             layoutIdFlag = {layoutIdFlag}
                    //             disable={true}
                    //           />
                    //         </Tooltip>
                    //       </Col>
                    //     );
                    //   }
                    // }
                    if (_x.split("-")[0] === "slider") {
                      return (
                        <Col span={12} key={index}>
                          <ImageSliderElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={`${_x}`}
                            label={`Image/GIF Slider`}
                            sliderImg={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x === "slider_random") {
                      return (
                        <Col span={24} key={index}>
                          <CheckBoxElememt
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                            disable={
                              (
                                ![
                                  CONSTANT.SuperAdmin,
                                  CONSTANT.SubAdmin,
                                  CONSTANT.Partner,
                                ].includes(AUTH.user.users_roles_id)
                                  ? AUTH.checkPlan(CONSTANT.AUTO_PLAY)
                                  : true
                              )
                                ? false
                                : true
                            }
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "video") {
                      return (
                        <Col span={12} key={index}>
                          <VideoElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "logo") {
                      return (
                        <Col span={12} key={index}>
                          <LogoElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                            logo={_x}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "bg_image") {
                      return (
                        <Col span={12} key={index}>
                          <BackgroundImageElement
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "price") {
                      return (
                        <Col span={12} key={index}>
                          <PriceElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "title") {
                      return (
                        <Col span={12} key={index}>
                          <TitleElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "youtube") {
                      return (
                        <Col span={12} key={index}>
                          <YoutubeElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "pdf") {
                      return (
                        <Col span={12} key={index}>
                          <PdfElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            images={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "ticker") {
                      return (
                        <Col span={12} key={index}>
                          <TickerElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "html") {
                      return (
                        <Col key={index} span={24}>
                          <TextElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "donor_wall") {
                      return (
                        <Col span={12} key={index}>
                          <DonorwallElement
                            setShow={setShow}
                            key={index}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={"Price Title"}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "event") {
                      return (
                        <Col span={12} key={index}>
                          <EventElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                          />
                        </Col>
                      );
                    }

                    if (_x.split("-")[0] === "twitt") {
                      return (
                        <Col span={12} key={index}>
                          <TweetElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "twitt_post") {
                      return (
                        <Col span={12} key={index}>
                          <TweetPostElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }

                    if (_x.split("-")[0] === "twitt_image") {
                      return (
                        <Col span={12} key={index}>
                          <TweetImageElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "twitt_ticker") {
                      return (
                        <Col span={12} key={index}>
                          <TweetTickerElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "twitt_embeded") {
                      return (
                        <Col span={12} key={index}>
                          <TweetEmbeddedElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "twitt_video") {
                      return (
                        <Col span={12} key={index}>
                          <TweetVideoElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "xval") {
                      return (
                        <>
                          <Col span={12} key={index}>
                            <XYValElement
                              setShow={setShow}
                              onChange={onChange}
                              form={form}
                              name={_x}
                              label={_x}
                            />
                          </Col>
                          <Col span={12} key={index + "yval"} />
                        </>
                      );
                    }
                    if (_x.split("-")[0] === "type") {
                      return (
                        <Col span={12} key={index}>
                          <TypeElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "google_slide_embeded") {
                      return (
                        <Col span={12} key={index}>
                          <TextAreaElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    if (_x.split("-")[0] === "html_layout") {
                      return (
                        <Col span={24} key={index}>
                          <HtmlLayoutElement
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                          {form.getFieldValue("html_layout") && (
                            <div
                              style={{
                                marginTop: "10px",
                                border: "solid 1px gray",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: form.getFieldValue("html_layout"),
                              }}
                            />
                          )}
                        </Col>
                      );
                    }
                    // for instagram Layout
                    if (_x.split("_")[0] === "insta") {
                      return (
                        <Col span={12} key={index}>
                          <TextFieldElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    // for tweeter Layout
                    if (_x.split("_")[0] === "tweet") {
                      return (
                        <Col span={12} key={index}>
                          <TextFieldElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    // for facebook Layout
                    if (_x.split("_")[0] === "facebook") {
                      return (
                        <Col span={12} key={index}>
                          <TextFieldElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    // for professor name field
                    if (_x === "professor_name") {
                      return (
                        <Col span={12} key={index}>
                          <TextFieldElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                            maxChar={20}
                          />
                        </Col>
                      );
                    }
                    // for professor designation field
                    if (_x === "designation") {
                      return (
                        <Col span={12} key={index}>
                          <TextFieldElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                            maxChar={25}
                          />
                        </Col>
                      );
                    }
                    // for professor bio-html field
                    if (_x === "bio") {
                      return (
                        <Col span={24} key={index}>
                          <TextAreaElement
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                    // for professor schedule field
                    if (_x === "professor_schedule") {
                      return (
                        <Col span={24} key={index}>
                          <ScheduleElement
                            setShow={setShow}
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                     // for mix layout field
                    if (_x === "mix_layout") {
                      return (
                        <Col span={24} key={index}>
                          <MixLayout
                            onChange={onChange}
                            form={form}
                            name={_x}
                            label={_x}
                            layout_id={img}
                            layoutIdFlag = {layoutIdFlag}
                          />
                        </Col>
                      );
                    }
                  })}
                </Row>
              );
            }
          }
        }}
      </Form.Item>
    </FormBox>
  );
});

export default FormComponent;
