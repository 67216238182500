import { faPenNib } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  FormInstance,
  message,
  Modal,
  Popover,
  Upload,
} from "antd";
import { RcFile, UploadFile } from "antd/lib/upload";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { StyleProps } from "../../../../store/LayoutStore/LayoutInterface";
import { UploadOutlined } from "@ant-design/icons";
import StyleModalComponent from "../components/StyleModal";
import {
  createUrl,
  planMessage,
  widgetStyles,
} from "../../../../config/Global";
import useStore from "../../../../store";
import { CONSTANT } from "../../../../config/Constant";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  images: any;
  layout_id: any;
  setShow?: (data: any) => void;
  layoutIdFlag?: boolean;
}

const PdfElement: React.FC<formInterface> = observer((props) => {
  const { form, onChange, name, label, layout_id, setShow, layoutIdFlag } = props;
  const [styleProps, setStyleProps] = useState<StyleProps>({
    styles: [],
    type: "",
  });
  const [applyStyleModal, setApplyStyleModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  const { LAYOUT, AUTH } = useStore();
  const openStyleModal = (data: StyleProps) => {
    setStyleProps(data);
    setApplyStyleModal(true);
  };
  const closeStyleModal = () => {
    onChange();
    setApplyStyleModal(false);
  };

  useEffect(() => {
    if (LAYOUT.editValues && form.getFieldValue(`${name}`)) {
      setFileList([
        {
          uid: "-1",
          name: form.getFieldValue(`${name}`),
          status: "done",
          url: createUrl(
            "/storage/media/file/" + form.getFieldValue(`${name}`)
          ),
        },
      ]);
    }
  }, [LAYOUT.editValues]);

  useEffect(() => {
    if (layout_id && layoutIdFlag) {
      setFileList([]);
      form.setFieldsValue({ [name]: null })
    }
  }, [layout_id]);

  const onPreview = (file: any) => {
    const url = file.url ? file.url : URL.createObjectURL(file?.originFileObj);
    window.open(url);
  };

  //For showing modal on file removal :Start
  const toggle = () => {
    setVisible((prev) => !prev);
    return false;
  };

  const deleteItem = () => {
    if (typeof form.getFieldValue(name) !== "object") {
      const filePath = "/storage/media/file/" + form.getFieldValue(name);
      LAYOUT.RemoveFileApi(LAYOUT.editValues.id, filePath);
      toggle();
    }
    setFileList([]);
    form.resetFields([name]);
    onChange();
    return true;
  };
  //For showing modal on file removal :End

  return (
    <>
      <Col>
        <Form.Item
          name={name}
          className="full-width-dragger"
          label={
            <div>
              {label.charAt(0).toUpperCase() + label.slice(1)}
              {AUTH.checkPlan(CONSTANT.INTERACTIVE_CONTENT_SUPPORT) ? (
                <Button
                  type="primary"
                  title="Apply style"
                  onClick={() =>
                    openStyleModal({
                      styles: widgetStyles.PdfElement(),
                      type: name,
                    })
                  }
                  className="ml-5 form-button-icon"
                >
                  <FontAwesomeIcon icon={faPenNib} />
                </Button>
              ) : (
                <Popover title={() => planMessage()}>
                  <Button
                    type="primary"
                    title="Apply style"
                    disabled={true}
                    onClick={() =>
                      openStyleModal({
                        styles: widgetStyles.PdfElement(),
                        type: name,
                      })
                    }
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faPenNib} />
                  </Button>
                </Popover>
              )}
            </div>
          }
          rules={LayoutRequest.pdf}
          extra="You can upload pdf for this widget"
        >
          <Upload
            accept=".pdf"
            multiple={false}
            fileList={fileList}
            onRemove={
              typeof form.getFieldValue(name) !== "object" ? toggle : deleteItem
            }
            onPreview={onPreview}
            onChange={({ fileList }: any) => {
              setFileList(fileList);
              form.setFieldsValue({
                [name]: fileList[0]?.originFileObj,
              });
              onChange();
            }}
            beforeUpload={(file: RcFile) => {
              const isPdf = file.type === "application/pdf";
              const isLt1M = file.size / 1024 / 1024 < 9;

              if (!isPdf) {
                message.error(`Please upload file should be in pdf`);
                return Upload.LIST_IGNORE;
              } else if (!isLt1M) {
                message.error("File size should not be more than 9 MB");
                return Upload.LIST_IGNORE;
              } else {
                setFileList([file]);
                return false;
              }
            }}
          >
            {fileList.length < 1 && (
              <Button icon={<UploadOutlined />}>Upload</Button>
            )}
          </Upload>
        </Form.Item>
        {applyStyleModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <StyleModalComponent
              visible={applyStyleModal}
              close={closeStyleModal}
              styleProps={styleProps}
              setShow={setShow}
            />
          </Form.Item>
        )}
      </Col>
      <Modal
        okText="Yes"
        cancelText="No"
        open={visible}
        onOk={deleteItem}
        onCancel={toggle}
        className="removeUploadModal commanModal"
      >
        <h4>Are you sure you want to permanently remove this Item ?</h4>
      </Modal>
    </>
  );
});

export default PdfElement;
